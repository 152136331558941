import React from 'react'
import PropTypes from 'prop-types'
import Map, { Marker } from 'google-maps-react'

import icLocationMainPartner from '../../../assets/images/icLocationMainPartner.svg'
import icLocation from '../../../assets/images/icLocationDefault.svg'
import icLocationActive from '../../../assets/images/icLocationActive.svg'

import icLocationMainPartnerPNG from '../../../assets/images/icLocationMainPartner.png'
import icLocationPNG from '../../../assets/images/icLocationDefault.png'
import icLocationActivePNG from '../../../assets/images/icLocationActive.png'

import styles from './styles.module.css'

const identity = (...a) => a

export default class MapComponent extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      places: [],
    }
  }

  _renderMarkers() {
    if (!this.props.places) {
      return
    }

    var is_internetExplorer11 = navigator.userAgent.toLowerCase().indexOf('trident') > -1
    return this.props.places.map(p => {
      return <Marker
                icon={(p.status === "Mainpartner") ? ({
                  url: is_internetExplorer11 ? icLocationMainPartnerPNG : icLocationMainPartner,
                }) : ((this.props.place && p.id === this.props.place.id) ? ({
                  url: is_internetExplorer11 ? icLocationActivePNG : icLocationActive,
                }) : ({
                 url: icLocation ? icLocationPNG : icLocation,
                }))}
                key={p.id}
                name={p.id}
                place={p}
                onClick={this.props.onMarkerClick}
                map={this.props.map}
                position={p.location && { lat: p.location.latitude, lng: p.location.longitude }} />
    })
  }

  _renderChildren() {
    const {children} = this.props

    if (React.Children.count(children) > 0) {
      return React.Children.map(children, c => {
        return React.cloneElement(c, this.props, {
          map: this.props.map,
          google: this.props.google
        })
      })
    } else {
      return this._renderMarkers()
    }
  }

  render() {
    const {
      map,
      google,
      place,
      onMove,
      onClick,
    } = this.props
    return (
      <Map
        region="DE"
        map={map}
        google={google}
        mapTypeControl={false}
        panControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        zoomControl={false}
        className={styles.map}
        zoom={12}
        center={place && place.location
          ? { lat: place.location.latitude, lng: place.location.longitude }
          : { lat: 48.1100209, lng: 11.5759915 }}
        initialCenter={{
          lat: 48.1100209,
          lng: 11.5759915,
        }}
        onRecenter={onMove}
        onDragend={onMove}
        onClick={onClick}
      >
        {this._renderChildren()}
      </Map>
    )
  }
}

MapComponent.propTypes = {
  google: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  onMarkerClick: PropTypes.func,
  onMove: PropTypes.func,
  onClick: PropTypes.func,
  place: PropTypes.object,
}
MapComponent.defaultProps = {
  place: null,
  onMove: () => {},
  onClick: () => {},
  onMarkerClick: identity,
}

