import React from 'react'
import PropTypes from 'prop-types'
import { Responsive } from 'semantic-ui-react'
import { GoogleApiWrapper } from 'google-maps-react'

import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'
import FilterView from '../../../components/Sidebar/FilterView/FilterView'
import Listing from '../../../components/Sidebar/Listing/Listing'
import MobileListing from '../../../components/Sidebar/Listing/MobileListing'
import MapComponent from '../Map/Map'

import styles from './styles.module.css'

class Containee extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      places: [],
      place: null,
      pagination: null,
      category: null,
      searchString: null
    }
  }

  onMapMove() {}

  onMarkerClick(item) {
    const {place} = item
    if (!item.id) {
      const selectedPlace = this.props.places.find(pl => pl.id === item.name)
        this.setState({ place: selectedPlace })
    } else {
      if (item !== this.state.place) {
        this.setState({ place: item })
      }
    }
  }

  onDeselect() {
    this.setState({ place: null })
  }

  onSearchStringChanged(searchString) {
    this.setState({ searchString: searchString })
  }

  onCategoryClick(category) {
    if (category === "Alle Kategorien") {
      this.setState({
        category: null,
        place: null
      })
    } else {
      this.setState({ 
        category: category,
        place: null
      })
    }
  }

  returnFilteredPlaces() {
    if (!this.props.places)
      return null

    if (!this.state.category && !this.state.searchString)
      return this.props.places

    if (!this.state.category) {
      return this.props.places.filter(place => place.name.toLowerCase().includes(this.state.searchString.toLowerCase()))
    }

    if (!this.state.searchString) {
      return this.props.places.filter(place => place.category === this.state.category)
    }

    return this.props.places.filter(place => place.name.toLowerCase().includes(this.state.searchString.toLowerCase()) && place.category === this.state.category)
  }

  returnCategories() {
    this.categories = ["Alle Kategorien"]
    if (this.props.places) {
      const cats = this.categories
      Array.prototype.push.apply(cats, this.props.places.map(partner => partner.category))
      this.categories = [...new Set(cats)]
      return this.categories
    }

    return this.categories
  }

  render() {
    return (
        <div>
          <Header 
            onSearch={this.onSearchStringChanged.bind(this)} />
          <div id="sidebarContainer" className={styles.sidebar}>
            <FilterView className={styles.container}
              onCategoryClick={this.onCategoryClick.bind(this)}
              categories={this.returnCategories()} />
            <Responsive minWidth={768}>
              <Listing 
                places={this.returnFilteredPlaces()}
                onLoadMore={this.props.onLoadMore}
                place={this.state.place}
                categories={this.returnCategories()}
                placesCount={this.props.placesCount}
                onClick={this.onMarkerClick.bind(this)} />
            </Responsive> 
          </div>

          <div id="content" className={styles.content}>
            <MapComponent
              google={this.props.google}
              places={this.returnFilteredPlaces()}
              place={this.state.place}
              loaded={this.props.loaded}
              router={this.context.router}
              onMove={this.onMapMove.bind(this)}
              onMarkerClick={this.onMarkerClick.bind(this)}
              zoom={this.props.zoom} />
              {!this.props.places ? (
                <div className={styles.loadingOverlay}>
                  <p>
                    {/* <Icon loading name='spinner' size="huge" /><br/> */}
                    Lade unsere Löwenstarke Partner...
                  </p>
                </div>
              ) : ""}
            <Responsive 
              {...Responsive.onlyMobile}>
                />
                <MobileListing
                places={this.returnFilteredPlaces()}
                place={this.state.place}
                categories={this.returnCategories()}
                placesCount={this.props.placesCount}
                onClick={this.onMarkerClick.bind(this)} />
            </Responsive> 
          </div>
          <Footer />
        </div>
    )
  }
}

Containee.propTypes = {
  router: PropTypes.object.isRequired,
  places: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GAPI_KEY,
  language: 'de',
})(Containee)
