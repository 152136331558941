import 'babel-polyfill'
import smoothscroll from 'smoothscroll-polyfill'

import React from 'react'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'
import { InMemoryCache } from 'apollo-cache-inmemory'
import './app.css'
import Routes from './routes'

// kick off the polyfill!
smoothscroll.polyfill()

const GRAPH_API = 'https://api-euwest.graphcms.com/v1/ck6ggi59b6x1601d7hnmo7bn7/master'

const client = new ApolloClient({
  uri: GRAPH_API,
  cache: new InMemoryCache(),
})

const HeadApp = () => (
  <ApolloProvider client={client}>
    <Routes />
  </ApolloProvider>
)

export default HeadApp
