import React from 'react';
import BlueItem from './BlueItem'
import ExpandedItem from './ExpandedItem'

import styles from './styles.module.css'

class Item extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }
  };

  onClick(e) {
    // handling for mobile slider
    if(this.props.isMobile){
      if(!this.props.currentSlide || (this.props.currentSlide && this.props.currentSlide.id == this.props.place.id)){
        this.setState({isOpen: !this.state.isOpen});
      }
    }else{
      this.setState({isOpen: !this.state.isOpen});
      this.props.onClick(this.props.place); // If we call this onMakerClicked the slider will not because it also updates its status
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState({isOpen: nextProps.isOpen});
  }


  render() {
    if (this.state.isOpen) {
      return (
        <ExpandedItem
          id={this.props.id}
          onMobile={ this.props.currentSlide }
          onClick={this.onClick.bind(this)}
          place={this.props.place} />
      )
    } else {
      return (
        <BlueItem
          id={this.props.id}
          onClick={this.onClick.bind(this)}
          place={this.props.place} />
      )
    }
  }
}

export default Item;