import React from 'react';
import { Responsive } from 'semantic-ui-react'
import styles from './styles.module.css'
import DropdownIcon from '../../../assets/images/ic-dropdown-white.svg'

class FilterView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selectedCategory: null
    }
  };

  onClick(e) {
    this.setState({ isOpen: !this.state.isOpen });
  }

  onMobileCategoryClick(cat, e) {
    this.setState({
       selectedCategory: cat
     });
    this.props.onCategoryClick(cat);
  }

  onCategoryClick(cat, e) {
    this.setState({
       isOpen: !this.state.isOpen,
       selectedCategory: cat
     });
    this.props.onCategoryClick(cat);
  }

  render() {
    if (!this.state.isOpen) {
      return (
        <div>
          <Responsive className={styles.filterView} maxWidth={767}>
            {(this.props.categories) ? this.props.categories.map(category => {
              return (
                <div 
                  key={category}
                  onClick={this.onMobileCategoryClick.bind(this, category)} 
                  className={[styles.category, ((!this.state.selectedCategory && category === "Alle Kategorien") || category === this.state.selectedCategory) ? styles.categoryActive : ''].join(' ')}>
                  {category}
                </div>
              )
            }) : ""}
          </Responsive>
          <Responsive minWidth={768} className={styles.filterButton} onClick={this.onClick.bind(this)}>
            <h2>{(this.state.selectedCategory && this.state.selectedCategory !== "Alle Kategorien") ? this.state.selectedCategory : "Kategorie wählen"}</h2>
            <img className={styles.filterIcon} src={DropdownIcon} width="24px" height="24px" />
          </Responsive>
        </div>
      )
    } else {
      return (
        <div className={styles.filterButton}>
          <h2 onClick={this.onClick.bind(this)}>Kategorie wählen</h2> 
          <img onClick={this.onClick.bind(this)} className={styles.filterIcon} src={DropdownIcon} width="24px" height="24px" />
          <div className={styles.filterView}>
            {(this.props.categories) ? this.props.categories.map(category => {
              return (
                <div 
                  key={category}
                  onClick={this.onCategoryClick.bind(this, category)} 
                  className={styles.category}>
                  {category}
                </div>
              )
            }) : ""}
          </div>
        </div>
      )
    }
  }
}

export default FilterView;
