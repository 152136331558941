import React from 'react'
import 'isomorphic-unfetch'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import Containee from './Containee/Containee'
import './styles.module.css'


const Container = () => (
  <Query query={partners} variables={{ skip: 0 }}>
    {({
      loading,
      error,
      data,
      fetchMore,
    }) => {
      if (error) return <div>{error}</div>
      if (loading) return <div>Loading..</div>
      const { partners, partnersConnection } = data
      const categories = partners.map((p) => p.category)
      return (
        <Containee
          onLoadMore={() => fetchMore({ variables: { skip: partners.length } })}
          placesCount={partnersConnection && partnersConnection.aggregate.count}
          categories={categories}
          places={partners}
        />
      )
    }}
  </Query>
)

export const partners = gql`
  query partners($skip: Int!) {
    partners(orderBy: id_ASC,skip: $skip) {
      id
      name
      status
      category
      street
      streetNr
      zipcode
      city
      location {
        longitude
        latitude
      }
      website
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      phoneNumber
      logo {
        handle
        url
        width
        height
      }
      images {
        handle
        width
        height
        url
      }
    },
    partnersConnection {
      aggregate {
        count
      }
    }
  }
`

export default Container
