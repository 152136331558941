import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import App from './containers/App/App'

const customHistory = createBrowserHistory()

const Routes = () => (
  <BrowserRouter history={customHistory}>
    <Route path="/" component={App} />
  </BrowserRouter>
)

export default Routes
