import React from 'react'
import { Grid, Responsive } from 'semantic-ui-react'
import classnames from 'classnames'

import bayerischeBlueLogo from '../../assets/images/partner/bayerische.png'
import bayerischeBwLogo from '../../assets/images/partner/bw_bayerische.png'
import nikeLogo from '../../assets/images/partner/Nike_Teamsports11.png'
import ahdLogo from '../../assets/images/partner/ahd.png'
import betLogo from '../../assets/images/partner/bet3000.png'
import is2Logo from '../../assets/images/partner/is2.png'
import hackerLogo from '../../assets/images/partner/hacker.png'
import nissanLogo from '../../assets/images/partner/nissan.png'
import manLogo from '../../assets/images/partner/man.png'
import spkLogo from '../../assets/images/partner/spk.png'
import linsterLogo from '../../assets/images/partner/linster.png'
import styles from './styles.module.css'

const Footer = () => (
  <div className={styles.footer}>
    <Grid style={{ margin: '0' }} className={styles.top}>
      <Responsive className={styles.top} maxWidth={1199}>
        <Grid.Column verticalAlign="middle" className={styles.mobileTop}>
          Hauptsponsor & Ausrüster
        </Grid.Column>
      </Responsive>
      <Responsive as={Grid.Row} minWidth={1200} className={styles.top}>
        <Grid.Column verticalAlign="middle" width={6} className={styles.topColumn}>
          Hauptsponsor
        </Grid.Column>
        <Grid.Column verticalAlign="middle" width={3} className={classnames(styles.borderedElement, styles.topColumn)}>
          Ausrüster
        </Grid.Column>
        <Grid.Column verticalAlign="middle" width={7} className={styles.topColumn}>
          Premium Partner
        </Grid.Column>
      </Responsive>
      <Grid.Row columns={16} className={styles.images}>
        <Grid.Column verticalAlign="middle" mobile={10} tablet={10} largeScreen={6} widescreen={6} className={styles.headPartner}>
          <a href="//www.diebayerische.de" target="_blank">
            <img
              onMouseOver={(e) => e.currentTarget.src = bayerischeBwLogo}
              onMouseOut={(e) => e.currentTarget.src = bayerischeBlueLogo}
              src={bayerischeBlueLogo}
              width="360"
              height="auto"
            />
          </a>
        </Grid.Column>
        <Grid.Column verticalAlign="middle" mobile={6} tablet={6} largeScreen={3} widescreen={3} className={classnames(styles.borderedElementGrey, styles.outfitter)}>
          <a href="//www.11teamsports.com" target="_blank">
            <img
              className={styles.nikeLogo}
              src={nikeLogo}
              width="200"
              height="auto"
              alt="Ausrüster"
            />
          </a>
        </Grid.Column>
        <Responsive className={styles.top} maxWidth={1199}>
          <Grid.Column verticalAlign="middle" className={styles.mobileTop}>
            Premium Partner
          </Grid.Column>
        </Responsive>
        <Grid.Column mobile={16} tablet={16} largeScreen={7} widescreen={7}>
          <Grid className={styles.premiumPartner}>
            <Grid.Row>

              <Grid.Column className={styles.partnerImage} mobile={5} tablet={3} computer={3} width={3} widescreen={3} largeScreen={3}>
                <a href="//www.hacker-pschorr.de" target="_blank">
                  <img src={hackerLogo} width="200" height="auto" alt="Hacker Pschorr" />
                </a>
              </Grid.Column>
              <Grid.Column className={styles.partnerImage} mobile={5} tablet={3} computer={3} width={3} widescreen={3} largeScreen={3}>
                <a href="http://www.mantruckandbus.com/de" target="_blank">
                  <img src={manLogo} width="300" height="auto" alt="MAN" />
                </a>
              </Grid.Column>
              <Grid.Column className={styles.partnerImage} mobile={5} tablet={3} computer={3} width={3} widescreen={3} largeScreen={3}>
                <a href="//www.linster.de" target="_blank">
                  <img src={linsterLogo} width="300" height="auto" alt="Linster" />
                </a>
              </Grid.Column>
              <Responsive className={styles.partnerImage} as={Grid.Column} mobile={5} tablet={3} computer={3} width={3} widescreen={3} largeScreen={3}>
                <a href="https://www.löwen-lieben-nissan.de/" target="_blank">
                  <img src={nissanLogo} width="300" height="auto" alt="Nissan" />
                </a>
              </Responsive>
              <Responsive className={styles.partnerImage} as={Grid.Column} mobile={5} tablet={3} computer={3} width={3} widescreen={3} largeScreen={3}>
                <a href="//www.sskm.de" target="_blank">
                  <img src={spkLogo} width="300" height="auto" alt="Stadtsparkasse München" />
                </a>
              </Responsive>
              <Grid.Column className={styles.partnerImage} mobile={5} tablet={3} computer={3} width={3} widescreen={3} largeScreen={3}>
                <a href="//www.ahd-sitzberger.de" target="_blank">
                  <img src={ahdLogo} width="300" height="auto" alt="AHD Sitzberger GmbH" />
                </a>
              </Grid.Column>
              <Grid.Column className={styles.partnerImage} mobile={5} tablet={3} computer={3} width={3} widescreen={3} largeScreen={3}>
                <a href="//www.bet3000.com/de/sportwetten" target="_blank">
                  <img src={betLogo} width="300" height="auto" alt="Bet3000" />
                </a>
              </Grid.Column>
              <Responsive className={styles.partnerImage} as={Grid.Column} mobile={5} tablet={3} computer={3} width={3} widescreen={3} largeScreen={3}>
                <a href="//www.is2.de" target="_blank">
                  <img src={is2Logo} width="300" height="auto" alt="is2" />
                </a>
              </Responsive>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Responsive as={Grid.Row} maxWidth={1199} className={styles.bottom}>
        <Grid.Column mobile={8}>
          <a target="_blank" href="http://tsv1860.de/de/impressum.htm" className={styles.footerButton}>Impressum</a>
        </Grid.Column>
        <Grid.Column style={{ border: 'none' }} mobile={8}>
          <a target="_blank" href="http://tsv1860.de/de/datenschutz.htm" className={styles.footerButton}>Datenschutz</a>
        </Grid.Column>
        <Grid.Column mobile={8}>
          <a target="_blank" href="http://tsv1860.de/de/agb.htm" className={styles.footerButton}>AGB</a>
        </Grid.Column>
        <Grid.Column style={{ border: 'none' }}>
          <a target="_blank" href="http://tsv1860.de/de/kontakt.htm" className={styles.footerButton}>Kontakt</a>
        </Grid.Column>
        <Grid.Column mobile={8}>
          <a target="_blank" href="http://tsv1860.de/de/newsletter.htm" className={styles.footerButton}>Newsletter</a>
        </Grid.Column>
        <Grid.Column style={{ border: 'none' }}>
          <a target="_blank" href="http://tsv1860.de/de/medienservice.htm" className={styles.footerButton}>Medienservice</a>
        </Grid.Column>
        <Grid.Column mobile={8}>
          <a target="_blank" href="http://tsv1860.de/de/jobs.htm" className={styles.footerButton}>Jobs</a>
        </Grid.Column>
      </Responsive>
      <Responsive maxWidth={1199} className={styles.rights}>
        © 2018 All rights reserved TSV 1860 München
      </Responsive>
      <Responsive minWidth={1200} className={styles.bottom}>
        <Responsive className={styles.rights}>
          © 2018 All rights reserved TSV 1860 München
        </Responsive>
        <div className={styles.buttons}>
          <a target="_blank" href="http://tsv1860.de/de/impressum.htm" className={styles.footerButton}>Impressum</a>
          <a target="_blank" href="http://tsv1860.de/de/datenschutz.htm" className={styles.footerButton}>Datenschutz</a>
          <a target="_blank" href="http://tsv1860.de/de/agb.htm" className={styles.footerButton}>AGB</a>
          <a target="_blank" href="http://tsv1860.de/de/kontakt.htm" className={styles.footerButton}>Kontakt</a>
          <a target="_blank" href="http://tsv1860.de/de/newsletter.htm" className={styles.footerButton}>Newsletter</a>
          <a target="_blank" href="http://tsv1860.de/de/medienservice.htm" className={styles.footerButton}>Medienservice</a>
          <a target="_blank" href="http://tsv1860.de/de/jobs.htm" className={styles.footerButton}>Jobs</a>
        </div>
      </Responsive>
    </Grid>
  </div>
)

export default Footer
