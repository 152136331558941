import React from 'react'
import Favicon from 'react-favicon'
import FaviconIco from '../../assets/images/1860_logo.jpg'
import Container from '../../views/Main/Container'
import Map from '../../views/Main/Map/Map'
import styles from './styles.module.css'

const App = () => (
  <div>
    <div>
      <Favicon url={FaviconIco} />
    </div>
    <div className={styles.wrapper}>
      <Container>
        <Map />
      </Container>
    </div>
  </div>
)

export default App
