import React from 'react'

import { PropTypes } from 'prop-types'
import classnames from 'classnames'
import { Responsive } from 'semantic-ui-react'
import Slider from 'react-slick'

import Item from './Item';
import logo from '../../../assets/images/1860_logo.jpg'
import styles from './styles.module.css'

export class MobileListing extends React.Component {
  constructor(props) {
    super(props);

    this.state = ({
      currentSlide: null
    })
  }

  afterPlaceChange(index) {
    this.setState({ currentSlide: this.props.places[index]});
    this.props.onClick(this.props.places[index]);
  }

  componentWillReceiveProps(nextProps){
    this.setState({ currentSlide: nextProps.place ? nextProps.place : null});

    if(this.props.places && nextProps.places && nextProps.place && this.slider){
      this.slider.slickGoTo(this.props.places.indexOf(nextProps.place));
    }
  }

  render() {

    let settings = {
      slidesToScroll: 1,
      focusOnSelect: true,
      arrows: false,
      centerPadding: '160px',
      adaptiveHeight: false,
      centerMode: true,
      swipe: true,
      speed: 800,
      rows: 1,
      afterChange: this.afterPlaceChange.bind(this),
      responsive: [
        {
          breakpoint: 700,
          settings: {
            centerPadding: '140px',
          }
        },
        {
          breakpoint: 650,
          settings: {
            centerPadding: '120px',
          }
        },
        {
          breakpoint: 600,
          settings: {
            centerPadding: '100px',
          }
        },
        {
          breakpoint: 550,
          settings: {
            centerPadding: '80px',
          }
        },
        {
          breakpoint: 500,
          settings: {
            centerPadding: '40px',
          }
        },
        {
          breakpoint: 450,
          settings: {
            centerPadding: '20px',
          }
        },
        {
          breakpoint: 400,
          settings: {
            centerPadding: '10px',
          }
        }
      ]
    }
    return (
      <div
       className={styles.sliderContainer}>
        <Slider ref={c => this.slider =c } className={styles.slider} {...settings}>
          {this.props.places ? this.props.places.map(place => {
            return (
              <div className={styles.slickSlide} key={'wrapper-'+place.id}>
                <Item
                  id={place.id}
                  place={place}
                  isMobile={true}
                  currentSlide={this.state.currentSlide ? this.state.currentSlide : null}
                  onClick={this.props.onClick}
                  key={place.id} />
              </div>
            )
          }) : ""}
        </Slider>
      </div>
    )
  }
}
MobileListing.propTypes = {
  places: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  place: PropTypes.object
}

export default MobileListing
