import React from 'react'
import { Responsive } from 'semantic-ui-react'
import GraphImg from "graphcms-image"

import styles from './styles.module.css'
import icWebsite from '../../../assets/images/ic-website.svg'
import icPhone from '../../../assets/images/ic-phone.svg'
import imagePlaceholder from '../../../assets/images/placeholder.jpg'

class ExpandedItem extends React.Component {
  onClick(e) {
    this.props.onClick(this.props.place)
  }

  render() {
    const {place} = this.props

    return (
      <div 
        ref="itemContainer"
        id={this.props.id}
        onClick={this.onClick.bind(this)}
        className={styles.expandedItem}>
        {(place.logo[0]) ? <Responsive minWidth={768} className={styles.partnerLogo} width="100" ><GraphImg image={place.logo[0]} maxWidth={100} fit='clip' fadeIn={false}/></Responsive> : ""}

        <div className={styles.imageWrapper}>
            {place.images[0] ? <GraphImg image={place.images[0]} maxWidth={450} /> : <img className={styles.image} src={imagePlaceholder} alt="Wrapper" />}
        </div>
        <div className={styles.expandedItemContent}>
          <h2 className={styles.title}>
            {place.name}
          </h2>
          <div className={styles.subtitle}>
            {place.street + " " + place.streetNr} • {place.city}
          </div>
          <div className={styles.hr}></div>
          <h4>
            Öffnungszeiten & Kontakt
          </h4>
          <div className={styles.content}>
            <div>
              <div className={styles.lineLeft}>Montag:</div>
              <div className={styles.lineRight}>{place.monday}</div>
            </div>
            <div>
              <div className={styles.lineLeft}>Dienstag:</div>
              <div className={styles.lineRight}>{place.tuesday}</div>
            </div>
            <div>
              <div className={styles.lineLeft}>Mittwoch:</div>
              <div className={styles.lineRight}>{place.wednesday}</div>
            </div>
            <div>
              <div className={styles.lineLeft}>Donnerstag:</div>
              <div className={styles.lineRight}>{place.thursday}</div>
            </div>
            <div>
              <div className={styles.lineLeft}>Freitag:</div>
              <div className={styles.lineRight}>{place.friday}</div>
            </div>
            <div>
              <div className={styles.lineLeft}>Samstag:</div>
              <div className={styles.lineRight}>{place.saturday}</div>
            </div>
            <div>
              <div className={styles.lineLeft}>Sonntag:</div>
              <div className={styles.lineRight}>{place.sunday}</div>
            </div>
          </div>
          <div className={styles.hr}></div>
          <div>
            <div className={styles.phone}>
              <img src={icWebsite} width="16" height="16" alt="Website" />
              <a href={'http://' + place.website} target="_blank"> {place.website}</a>
            </div>
            <div className={styles.phone}>
              <img src={icPhone} alt="Phonenumber" width="16" height="16" />
              <a href={'tel:' + place.phoneNumber}>{place.phoneNumber}</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ExpandedItem
