import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Item from './Item'
import styles from './styles.module.css'

export class Listing extends React.Component {


  render() {
    var lastPlace
    var lastEle
    var sidebarContainer = document.getElementById("sidebarContainer")
    var scrollContainer = document.getElementById("scrollContainer")
    if (scrollContainer && sidebarContainer && this.props.place) {
      lastPlace = this.props.place
        setTimeout(function() { // timeout needed because of exanding element
          lastEle = document.getElementById(lastPlace.id)
          if(lastEle){
            sidebarContainer.scrollTo({ top: lastEle.offsetTop - scrollContainer.offsetTop + 90, behavior: 'smooth'})
          }
        }, 200)

    }
    var settings = {
      arrows: false,
      infinite: false,
      swipeToSlide: true,
      draggable: true,
      swipe: true
    }
    return (
      <div 
        id="scrollContainer" 
        onClickCapture={this.props.onDeselect}
        className={classnames(styles.container)}>
        {this.props.places ? this.props.places.map(place => {
          return (
            <Item
              className={styles.item}
              id={place.id}
              place={place}
              isOpen={this.props.place && this.props.place.id == place.id ? true : false}
              onClick={this.props.onClick}
              key={place.id} />
          )
        }) : ""}
      </div>
    )
  }
}

Listing.propTypes = {
  places: PropTypes.array,
  place: PropTypes.object,
  onClick: PropTypes.func,
}

Listing.defaultProps = {
  places: null,
  place: null,
  onClick: () => {},
}

export default Listing
