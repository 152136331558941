import React from 'react'
import ReactDOM from 'react-dom'

import App from './app'

const mountNode = document.querySelector('#root')
ReactDOM.render(
  <App />,
  mountNode,
)
