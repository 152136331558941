import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import {
  Image,
  Menu,
  Grid,
  Responsive,
} from 'semantic-ui-react'

import styles from './styles.module.css'
import headerLogo from '../../assets/images/1860_logo_negativ.png'
import logo from '../../assets/images/1860_logo.png'
import bayerische from '../../assets/images/bayerische.png'
import bayerischeSmall from '../../assets/images/bayerische_small.png'
import imgArrowDown from '../../assets/images/icArrowDown.svg'
import icSearch from '../../assets/images/ic-search.svg'

export default class Header extends React.Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)

    this.state = {
      headerMinimized: false,
      searchString: null,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  onSearch(event) {
    const searchString = event.target.value
    this.setState({ searchString })
    this.props.onSearch(searchString.toLowerCase())
  }

  onKeyPress(e) {
    if (e.which === 13) this.scrollDown()
  }

  handleScroll() {
    if (window.scrollY > 390) {
      this.setState({ headerMinimized: true })
    } else {
      this.setState({ headerMinimized: false })
    }
  }

  scrollDown() {
    window.scrollTo({ top: 450, left: 0, behavior: 'smooth' })
  }

  renderHeaderSearchBar() {
    return (
      <Responsive minWidth={1024} className={styles.minimizedSearchLabel}>
        <input
          className={styles.searchInput}
          value={this.state.searchString || ''}
          onKeyPress={this.onKeyPress.bind(this)}
          onChange={this.onSearch.bind(this)}
          type="text"
          placeholder="Löwenstarke Partner finden..."
        />
        <button
          onClick={this.scrollDown.bind(this)}
          className={styles.searchButton}
        >
          <img src={icSearch} className={styles.icon} />
        </button>
      </Responsive>
    )
  }

  render() {
    return (
      <div className={classnames(styles.bigTopBar, styles.shadow)}>
        <Menu inverted className={styles.topbar} attached="top">
          <Menu.Menu onClick={() => window.scrollTo({ top: 0, left: 0, bahavior: 'smooth' })} className={classnames(styles.headerTitle, styles.centerVertically, styles.third)}>
            <img
              className={classnames(styles.centerVertically, styles.imgSize)}
              src={headerLogo}
            />
            <div className={styles.headerLogo}>Löwenmarktplatz</div>
          </Menu.Menu>
          <Menu.Menu className={classnames(styles.centerVertically, styles.headerSearchBar, styles.third)}>
            {this.state.headerMinimized ? this.renderHeaderSearchBar() : <div /> }
          </Menu.Menu>
          <Menu.Menu position="right">
            <a href="http://www.tsv1860.de/de/loewenversicherung.htm" target="_blank">
              <Responsive minWidth={768} as={Image} className={classnames(styles.centerVertically, styles.bayerisch)} src={bayerische} height="34" width="200" />
              <Responsive {...Responsive.onlyMobile} as={Image} src={bayerischeSmall} className={classnames(styles.centerVertically, styles.bayerisch)} height="52" width="47" />
            </a>
          </Menu.Menu>
        </Menu>
        <Grid style={{ margin: '0', marginTop: '80px' }} className={styles.extendedTopBar}>
          <Grid.Row>
            <Responsive as={Grid.Column} minWidth={768} tablet={1} computer={2} largeScreen={4} widescreen={5} />
            <Grid.Column className={styles.titleContainer} mobile={16} tablet={14} computer={12} largeScreen={8} widescreen={6} style={{ marginTop: '45px' }}>
              <img className={styles.mainLogo} src={logo} />
              <div className={styles.titleTexts}>
                <h1 className={styles.title}>Löwen</h1>
                <p className={styles.subtitle}>SECHZIG</p>
              </div>
              <Responsive minWidth={768} className={styles.searchLabel}>
                <div className={styles.inputWrapper}>
                  <input onKeyPress={this.onKeyPress.bind(this)} onChange={this.onSearch.bind(this)} value={this.state.searchString || ''} type="text" placeholder="Löwenstarke Partner finden..." />
                </div>
                <button type="submit" onClick={this.scrollDown.bind(this)}>Suchen</button>
              </Responsive>
              <Responsive {...Responsive.onlyMobile} className={styles.searchLabel}>
                <div className={styles.inputWrapper}>
                  <input onKeyPress={this.onKeyPress.bind(this)} onChange={this.onSearch.bind(this)} value={this.state.searchString || ''} type="text" placeholder="Löwenstarke Partner finden..." />
                </div>
                <div onClick={this.scrollDown.bind(this)} className={styles.mobileSearchButton}>
                  <img src={icSearch} width="14" height="15" />
                </div>
              </Responsive>
            </Grid.Column>
            <Responsive as={Grid.Column} minWidth={768} tablet={1} computer={2} largeScreen={4} widescreen={5} />
          </Grid.Row>
        </Grid>
        <Responsive className={styles.linkFlex} as={Link} minWidth={801} onClick={this.scrollDown.bind(this)}>
          <div className={styles.downLink}>
            <img src={imgArrowDown} height="24" width="24" />
          </div>
        </Responsive>
      </div>
    )
  }
}
