import React from 'react'
import { PropTypes as T } from 'prop-types'
import { Responsive } from 'semantic-ui-react'
import GraphImg from "graphcms-image"
import imagePlaceholder from '../../../assets/images/placeholder.jpg'

import styles from './styles.module.css'

export class BlueItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hovered: false
    }
  }

  onClick(e) {
    this.props.onClick(this.props.place)
  }

  render() {
    const { place } = this.props
    return (
      <div
        id={this.props.id}
        onClick={this.onClick.bind(this)}
        className={styles.item}>
          <Responsive style={{display: "inline-block"}} {...Responsive.onlyMobile} className={styles.itemImage}>
            {place.images[0] ? <GraphImg image={place.images[0]} maxWidth={450} /> : <img src={imagePlaceholder} />}
          </Responsive>
          <div className={styles.itemTitle}>
            <h2 className={styles.title}>{place.name}</h2>
            <div className={styles.subtitle}>{place.street + " " + place.streetNr}</div>
          </div>
      </div>
    )
  }
}

BlueItem.propTypes = {
  place: T.object.isRequired,
  onHighlight: T.func,
}

BlueItem.defaultProps = {
  place: T.object
}

export default BlueItem
